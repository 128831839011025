import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'es6-promise/auto';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import VueMask from 'v-mask';
import Swal from 'sweetalert2';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';
import CNPJ from './validations/CNPJ';
import CPF from './validations/CPF';
import CEP from './validations/CEP';
import DateV from './validations/Date';
import DueDate from './validations/DueDate';
import OlderDate from './validations/OlderDate';
import Celular from './validations/Celular';
import EmailRepeated from './validations/EmailRepeated';
import CPFRepeated from './validations/CPFRepeated';
import TelefoneRepeated from './validations/TelefoneRepeated';
import ClienteCelularExists from './validations/ClienteCelularExists';
import ClienteCPFExists from './validations/ClienteCPFExists';
import ClienteEmailExists from './validations/ClienteEmailExists';
import DependenteCelularExists from './validations/DependenteCelularExists';
import DependenteCPFExists from './validations/DependenteCPFExists';
import DependenteEmailExists from './validations/DependenteEmailExists';
import TitularCelularExists from './validations/TitularCelularExists';
import TitularCPFExists from './validations/TitularCPFExists';
import TitularEmailExists from './validations/TitularEmailExists';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserMinus } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUserMinus, faUserPlus, faUserCog, faExclamationCircle, faCheckCircle, faBars, faPhone, faEnvelope, faUserTie, faBuilding, faUsers, faWallet, faExclamationTriangle);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('pt_BR', pt_BR)

// Add a rule.
extend('cnpj', {
  validate: value => CNPJ(value),
  message: 'CNPJ inválido.'
});

extend('cpf', {
  validate: value => CPF(value),
  message: 'CPF inválido.'
});

extend('cep', {
  validate: value => CEP(value),
  message: 'CEP inválido.'
});

extend('celular', {
  validate: value => Celular(value),
  message: 'número de {_field_} inválido.'
});

extend('date', {
  validate: value => DateV(value),
  message: '{_field_} é inválida.'
});

extend('dueDate', {
  validate: value => DueDate(value),
  message: '{_field_} é inválida.'
});

extend('olderDate', {
  validate: value => OlderDate(value),
  message: '{_field_} é inválida.'
});

extend('celularSeMaior', {
  params: ['birth'],
  validate(value, { birth }) {
    if (birth === '' || birth === undefined) {
      return false;
    }
    const birthday = new Date(birth);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    const idade = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (idade < 18) {
      if (value !== '') {
        return Celular(value);
      } else {
        return true;
      }
    } else {
      return Celular(value);
    }
  },
  computesRequired: true,
  message: 'número de {_field_} válido é requerido.'
});

extend('emailRepeated', {
  validate: (value, values) => EmailRepeated(value, values),
  message: '{_field_} está duplicado.'
});

extend('cpfRepeated', {
  validate: (value, values) => CPFRepeated(value, values),
  message: '{_field_} está duplicado.'
});

extend('telefoneRepeated', {
  validate: (value, values) => TelefoneRepeated(value, values),
  message: '{_field_} está duplicado.'
});

extend('clienteCelularExists', {
  validate: (value) => ClienteCelularExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('clienteCPFExists', {
  validate: (value) => ClienteCPFExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('clienteEmailExists', {
  validate: (value) => ClienteEmailExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('dependenteCelularExists', {
  validate: (value) => DependenteCelularExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('dependenteCPFExists', {
  validate: (value) => DependenteCPFExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('dependenteEmailExists', {
  validate: (value) => DependenteEmailExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('titularCelularExists', {
  validate: (value) => TitularCelularExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('titularCPFExists', {
  validate: (value) => TitularCPFExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

extend('titularEmailExists', {
  validate: (value) => TitularEmailExists(value),
  message: '{_field_} já existe no sistema e não está cancelado.'
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$swal = Swal;

Vue.component('my-nav', require('./components/shared/Nav.vue').default);
Vue.component('my-footer', require('./components/shared/Footer.vue').default);
Vue.component('my-button', require('./components/shared/Button.vue').default);
Vue.component('my-hr', require('./components/shared/Hr.vue').default);
Vue.component('my-input-text', require('./components/shared/InputText.vue').default);
Vue.component('my-input-date', require('./components/shared/InputDate.vue').default);
Vue.component('my-input-older-date', require('./components/shared/InputOlderDate.vue').default);
Vue.component('my-input-due-date', require('./components/shared/InputDueDate.vue').default);
Vue.component('my-input-phone', require('./components/shared/InputPhone.vue').default);
Vue.component('my-input-select', require('./components/shared/InputSelect.vue').default);
Vue.component('my-input-number', require('./components/shared/InputNumber.vue').default);
Vue.component('my-progress-bar', require('./components/shared/ProgressBar.vue').default);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueMask);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
