<template>
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated bg-custom-primary"
      role="progressbar"
      :aria-valuenow="barValue"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: `${barValue}%` }"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    barValue: Number,
  },
};
</script>