import Vue from 'vue'
import VueRouter from 'vue-router'
import Load from '../views/Load.vue'
import Begin from '../views/Begin.vue'
import Company from '../views/Company.vue'
import End from '../views/End.vue'
import Error from '../views/Error.vue'
import Holder from '../views/Holder.vue'
import Payment from '../views/Payment.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/begin',
    name: 'begin',
    component: Begin,
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
  },
  {
    path: '/end',
    name: 'end',
    component: End,
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    props: true,
  },
  {
    path: '/holder',
    name: 'holder',
    component: Holder,
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
  },
  {
    path: '/',
    name: 'load',
    component: Load,
  },
]

const router = new VueRouter({
  mode: 'abstract',
  routes
})

export default router
