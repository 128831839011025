<template>
  <div id="error" class="fullscreen">
    <div class="text-center">
      <font-awesome-icon icon="exclamation-triangle" size="7x" class="mb-3" />
      <h4>Erro</h4>
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["message"],
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
</style>