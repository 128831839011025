import Pagamentos from './PAGAMENTOS';
import Titular from './Titular';

export default class Empresa {
    constructor() {
        this.cnpj = ''
        this.razaoSocial = ''
        this.nomeFantasia = ''
        this.cidade = ''
        this.bairro = ''
        this.uf = ''
        this.cep = ''
        this.endereco = ''
        this.numero = ''
        this.complemento = ''
        this.telefone = ''
        this.celular = ''
        this.email = ''
        this.nomeResponsavel = ''
        this.cargo = ''
        this.dataAbertura = ''
        this.dataNascimentoResponsavel = ''
        this.dataVencimento = ''
        this.cpfResponsavel = ''
        this.cv = ''
        this.op = ''
        this.pagamento = Pagamentos.BOLETO_MENSAL
        this.version = process.env.VUE_APP_DATA_VERSION

        this.titulares = [
        ]
    }

    get cnpjClean() {
        return this.cnpj.replace(/[^\d]+/g, "")
    }

    get cepClean() {
        return this.cep.replace(/[^\d]+/g, "")
    }

    get telefoneClean() {
        return this.telefone.replace(/[^\d]+/g, "")
    }

    get celularClean() {
        return this.celular.replace(/[^\d]+/g, "")
    }

    get cpfResponsavelClean() {
        return this.cpfResponsavel.replace(/[^\d]+/g, "")
    }

    prepareToSendPre() {
        return {
            "cnpj": this.cnpjClean,
            "razao_social": this.razaoSocial.trim(),
            "nome_fantasia": this.nomeFantasia.trim(),
            "cidade": this.cidade.trim(),
            "bairro": this.bairro.trim(),
            "uf": this.uf.trim(),
            "cep": this.cepClean,
            "endereco": this.endereco.trim(),
            "numero": this.numero,
            "complemento": this.complemento.trim(),
            "telefone": this.telefoneClean,
            "celular": this.celularClean,
            "email": this.email.trim(),
            "nome_responsavel": this.nomeResponsavel.trim(),
            "cargo": this.cargo.trim(),
            "id_parceiro": this.cv,
            "id_corretor": this.op,
            "data_abertura": this.dataAbertura,
            "data_nascimento_responsavel":
                this.dataNascimentoResponsavel,
            "cpf_responsavel": this.cpfResponsavelClean,

        }
    }

    prepareToSendFinal(idProdutoPME) {
        return {
            "cnpj": this.cnpjClean,
            "razao_social": this.razaoSocial.trim(),
            "nome_fantasia": this.nomeFantasia.trim(),
            "cidade": this.cidade.trim(),
            "bairro": this.bairro.trim(),
            "uf": this.uf.trim(),
            "cep": this.cepClean,
            "endereco": this.endereco.trim(),
            "numero": this.numero,
            "complemento": this.complemento.trim(),
            "telefone": this.telefoneClean,
            "celular": this.celularClean,
            "email": this.email.trim(),
            "nome_responsavel": this.nomeResponsavel.trim(),
            "cargo": this.cargo.trim(),
            "id_parceiro": this.cv,
            "id_corretor": this.op,
            "data_abertura": this.dataAbertura,
            "data_nascimento_responsavel":
                this.dataNascimentoResponsavel,
            "cpf_responsavel": this.cpfResponsavelClean,
            "data_vencimento": this.dataVencimento,
            "id_produto_pme": idProdutoPME,
            "titulares": this.titulares.map((titular) => {
                return titular.prepareToSendFinal();
            })

        }
    }

    toJson() {
        return JSON.stringify(this)
    }

    fromJson(data) {
        try {
            const converted = JSON.parse(data);

            this.cnpj = converted.cnpj ?? ''
            this.razaoSocial = converted.razaoSocial ?? ''
            this.nomeFantasia = converted.nomeFantasia ?? ''
            this.cidade = converted.cidade ?? ''
            this.bairro = converted.bairro ?? ''
            this.uf = converted.uf ?? ''
            this.cep = converted.cep ?? ''
            this.endereco = converted.endereco ?? ''
            this.numero = converted.numero ?? ''
            this.complemento = converted.complemento ?? ''
            this.telefone = converted.telefone ?? ''
            this.celular = converted.celular ?? ''
            this.email = converted.email ?? ''
            this.nomeResponsavel = converted.nomeResponsavel ?? ''
            this.cargo = converted.cargo ?? ''
            this.dataAbertura = converted.dataAbertura ?? ''
            this.dataNascimentoResponsavel = converted.dataNascimentoResponsavel ?? ''
            this.dataVencimento = converted.dataVencimento ?? ''
            this.cpfResponsavel = converted.cpfResponsavel ?? ''
            this.cv = converted.cv ?? ''
            this.op = converted.op ?? ''
            this.version = converted.version ?? ''


            this.titulares = converted.titulares.map((titular) => {
                const novoTitular = new Titular();
                novoTitular.from(titular)
                return novoTitular;
            })

        } catch (error) {
            console.log(error);
            throw 'Erro na decodificação dos dados salvos';
        }
    }
}