<template>
  <button class="btn">{{ title }}</button>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
  },
  methods: {},
};
</script>
<style scoped>
</style>