<template>
  <ValidationObserver v-slot="{ invalid }" ref="form">
    <div class="container" id="main-container">
      <div class="row justify-content-center my-4">
        <div class="col-12 col-lg-8">
          <div class="container">
            <h4 class="mb-2 mt-4 color-custom-primary">
              <strong> Cadastro dos Titulares </strong>
            </h4>
            <my-hr />
            <div id="titulares-area">
              <transition-group name="list">
                <div
                  class="card mt-4"
                  v-for="(titular, index) in empresa.titulares"
                  :key="`b-${index}`"
                >
                  <div class="card-header row mx-0 p-2">
                    <h5 class="card-title col-10">Titular</h5>
                    <button
                      class="btn btn-sm btn-danger col-2"
                      @click="remTitular(index)"
                    >
                      <font-awesome-icon icon="user-minus" />
                    </button>
                  </div>
                  <div class="card-body">
                    <form @submit.prevent="">
                      <my-input-text
                        label="Nome"
                        rules="required|min:3|max:150|alpha_spaces"
                        v-model="titular.nome"
                        name="nome"
                        class="mb-3"
                        :onBlurEmmitName="save"
                      />
                      <my-input-text
                        label="CPF"
                        :rules="
                          'required|cpf|cpfRepeated:' +
                          cpfs +
                          '|titularCPFExists'
                        "
                        v-model="titular.cpf"
                        name="cpf"
                        mask="###.###.###-##"
                        class="mb-3"
                        :onBlurEmmitName="save"
                      />
                      <my-input-text
                        label="Nome da mãe"
                        rules="required|min:3|max:150|alpha_spaces"
                        v-model="titular.nomeMae"
                        name="nome da mãe"
                        class="mb-3"
                        :onBlurEmmitName="save"
                      />
                      <my-input-older-date
                        label="Data de nascimento"
                        rules="required|olderDate"
                        v-model="titular.dataNascimento"
                        name="data de nascimento"
                        class="mb-3"
                        :onBlurEmmitName="save"
                      />
                      <my-input-phone
                        label="Celular"
                        :rules="
                          'required|celular|telefoneRepeated:' +
                          telefones +
                          '|titularCelularExists'
                        "
                        v-model="titular.celular"
                        name="celular"
                        class="mb-3"
                        :onBlurEmmitName="save"
                      />
                      <my-input-text
                        label="E-mail"
                        :rules="
                          'email|emailRepeated:' +
                          emails +
                          '|titularEmailExists'
                        "
                        v-model="titular.email"
                        name="email"
                        class="mb-3"
                        :onBlurEmmitName="save"
                      />
                    </form>
                    <h5>Dependentes</h5>
                    <ul class="list-group">
                      <transition-group name="list">
                        <li
                          v-for="(dependente, indexD) in titular.dependentes"
                          :key="`d-${indexD}`"
                          class="list-group-item"
                        >
                          <ValidationObserver v-slot="{ valid }">
                            <div
                              class="row justify-content-between align-items-center px-4"
                            >
                              <div class="col-1">
                                <font-awesome-icon
                                  v-show="!valid"
                                  icon="exclamation-circle"
                                  class="color-red"
                                  size="lg"
                                />
                                <font-awesome-icon
                                  v-show="valid"
                                  icon="check-circle"
                                  class="color-green"
                                  size="1x"
                                />
                              </div>
                              <h6 class="col-7">
                                {{ dependente.nome }}
                              </h6>
                              <div
                                class="btn-group col-sm-4 col-12"
                                role="group"
                              >
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  data-bs-toggle="modal"
                                  :data-bs-target="`#modal-dependente-${index}-${indexD}`"
                                >
                                  <font-awesome-icon icon="user-cog" />
                                </button>
                                <button
                                  class="btn btn-sm btn-danger"
                                  @click="remDependente(index, indexD)"
                                >
                                  <font-awesome-icon icon="user-minus" />
                                </button>
                              </div>
                            </div>
                            <!-- Modal -->
                            <div
                              class="modal fade"
                              :id="`modal-dependente-${index}-${indexD}`"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              :aria-labelledby="`modal-dependente-label-${index}-${indexD}`"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-scrollable">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5
                                      class="modal-title"
                                      :id="`modal-dependente-label-${index}-${indexD}`"
                                    >
                                      Dependente
                                    </h5>
                                    <button
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div class="modal-body">
                                    <form @submit.prevent="">
                                      <my-input-text
                                        label="Nome"
                                        rules="required|min:3|max:150|alpha_spaces"
                                        v-model="dependente.nome"
                                        name="nome"
                                        class="mb-3"
                                        :onBlurEmmitName="save"
                                      />
                                      <my-input-text
                                        label="CPF"
                                        :rules="
                                          'cpf|cpfRepeated:' +
                                          cpfs +
                                          '|dependenteCPFExists'
                                        "
                                        v-model="dependente.cpf"
                                        name="cpf"
                                        mask="###.###.###-##"
                                        class="mb-3"
                                        :onBlurEmmitName="save"
                                      />
                                      <my-input-text
                                        label="Nome da mãe"
                                        rules="required|min:3|max:150|alpha_spaces"
                                        v-model="dependente.nomeMae"
                                        name="nome da mãe"
                                        class="mb-3"
                                        :onBlurEmmitName="save"
                                      />
                                      <my-input-select
                                        label="Parentesco com o titular"
                                        rules="required"
                                        v-model="dependente.grauParentesco"
                                        name="parentesco"
                                        :options="parentescos"
                                        class="mb-3"
                                        :onBlurEmmitName="save"
                                      />
                                      <ValidationObserver
                                        :ref="`observer-dependente-${index}`"
                                      >
                                        <my-input-date
                                          label="Data de nascimento"
                                          rules="required|date"
                                          v-model="dependente.dataNascimento"
                                          name="data-de-nascimento"
                                          class="mb-3"
                                          :onBlurEmmitName="save"
                                        />
                                        <my-input-phone
                                          label="Celular"
                                          :rules="
                                            'celularSeMaior:@data-de-nascimento|telefoneRepeated:' +
                                            telefones +
                                            '|dependenteCelularExists'
                                          "
                                          v-model="dependente.celular"
                                          name="celular"
                                          class="mb-3"
                                          :onBlurEmmitName="save"
                                        />
                                      </ValidationObserver>
                                      <my-input-text
                                        label="E-mail"
                                        :rules="
                                          'email|emailRepeated:' +
                                          emails +
                                          '|dependenteEmailExists'
                                        "
                                        v-model="dependente.email"
                                        name="email"
                                        class="mb-3"
                                        :onBlurEmmitName="save"
                                      />
                                    </form>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      data-bs-dismiss="modal"
                                    >
                                      Adicionar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ValidationObserver>
                        </li>
                      </transition-group>
                    </ul>
                    <div class="text-center">
                      <button
                        class="btn btn-primary mx-4 mt-4"
                        @click="addDependente(index)"
                      >
                        Adicionar Dependente
                        <font-awesome-icon icon="user-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </transition-group>
              <div class="d-grid">
                <button class="btn btn-primary mt-4 mb-5" @click="addTitular">
                  Adicionar Titular
                  <font-awesome-icon icon="user-plus" />
                </button>
              </div>
            </div>

            <!-- pra aparecer embaixo-->
            <div class="justify-content-center col my-2" id="metodo-baixo">
              <hr class="bg-custom-primary mb-4" />
              <div class="container">
                <table class="table table-sm table-hover table-curved">
                  <thead class="bg-custom-primary text-white">
                    <tr>
                      <th class="text-center" scope="col">Plano</th>
                      <th class="text-center" scope="col">Vida boleto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="plano of planos" :key="plano.id">
                      <td class="text-center">{{ plano.produto_pme_nome }}</td>
                      <td class="text-center">R${{ plano.valor_boleto }}</td>
                    </tr>
                  </tbody>
                </table>
                <ValidationObserver>
                  <my-input-select
                    label="Método de pagamento"
                    rules="required"
                    v-model="empresa.pagamento"
                    name="metodo"
                    :options="metodos"
                  />
                </ValidationObserver>
                <table class="table table-sm table-hover table-curved mt-3">
                  <thead class="bg-custom-primary text-white">
                    <tr>
                      <th class="text-center" scope="col">Vidas</th>
                      <th class="text-center" scope="col">Plano Atual</th>
                      <th class="text-center" scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">{{ totalVidas }}</td>
                      <td class="text-center">
                        {{ planoAtualNome }}
                      </td>
                      <td class="text-center">
                        {{ totalValor ? `R$ ${totalValor}` : "" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-grid gap-2">
                  <my-button
                    class="btn btn-success mt-5 btn-lg"
                    :disabled="invalid || planoAtual === null"
                    :title="'Continuar'"
                    @click.native="continuar"
                  />
                  <my-button
                    class="btn btn-success btn-lg"
                    title="Voltar"
                    @click.native="voltar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Pra aparecer do lado-->
        <div class="justify-content-center col-4 mt-2" id="metodo-lado">
          <div class="position-fixed">
            <div class="container row">
              <table class="table table-sm table-hover table-curved col-12">
                <thead class="bg-custom-primary text-white">
                  <tr>
                    <th class="text-center" scope="col">Plano</th>
                    <th class="text-center" scope="col">Vida boleto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="plano of planos" :key="plano.id">
                    <td class="text-center">{{ plano.produto_pme_nome }}</td>
                    <td class="text-center">R${{ plano.valor_boleto }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="col-12">
                <ValidationObserver>
                  <my-input-select
                    label="Método de pagamento"
                    rules="required"
                    v-model="empresa.pagamento"
                    name="metodo"
                    :options="metodos"
                  />
                </ValidationObserver>
              </div>
              <table
                class="table table-sm table-hover table-curved col-12 mt-3"
              >
                <thead class="bg-custom-primary text-white">
                  <tr>
                    <th class="text-center" scope="col">Vidas</th>
                    <th class="text-center" scope="col">Plano Atual</th>
                    <th class="text-center" scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">{{ totalVidas }}</td>
                    <td class="text-center">
                      {{ planoAtualNome }}
                    </td>
                    <td class="text-center">
                      {{ totalValor ? `R$ ${totalValor}` : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-grid gap-2">
                <my-button
                  class="btn btn-success mt-4 btn-lg"
                  :disabled="invalid || planoAtual === null"
                  title="Continuar"
                  @click.native="continuar"
                />
                <my-button
                  class="btn btn-success btn-lg"
                  title="Voltar"
                  @click.native="voltar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import Dependente from "../models/Dependente";
import Titular from "../models/Titular";
import { Modal } from "bootstrap";
import Pagamentos from "../models/PAGAMENTOS";
export default {
  props: ["planos", "empresa", "metodos", "parentescos"],
  computed: {
    emails: function () {
      const emailsSet = new Set();
      const emailsRepeated = [];
      this.empresa.titulares.map((titular) => {
        if (titular.email) {
          const emailLower = titular.email.toLowerCase();
          if (emailsSet.has(emailLower)) {
            emailsRepeated.push(emailLower);
          } else {
            emailsSet.add(emailLower);
          }
        }
        titular.dependentes.map((dependente) => {
          if (dependente.email) {
            const emailLower = dependente.email.toLowerCase();
            if (emailsSet.has(emailLower)) {
              emailsRepeated.push(emailLower);
            } else {
              emailsSet.add(emailLower);
            }
          }
        });
      });
      return emailsRepeated.join(",");
    },
    telefones: function () {
      const telefonesSet = new Set();
      const telefonesRepeated = [];
      this.empresa.titulares.map((titular) => {
        if (titular.celular) {
          if (telefonesSet.has(titular.celular)) {
            telefonesRepeated.push(titular.celular);
          } else {
            telefonesSet.add(titular.celular);
          }
        }
        titular.dependentes.map((dependente) => {
          if (dependente.celular) {
            if (telefonesSet.has(dependente.celular)) {
              telefonesRepeated.push(dependente.celular);
            } else {
              telefonesSet.add(dependente.celular);
            }
          }
        });
      });
      return telefonesRepeated.join(",");
    },
    cpfs: function () {
      const cpfsSet = new Set();
      const cpfsRepeated = [];
      this.empresa.titulares.map((titular) => {
        if (titular.cpf) {
          if (cpfsSet.has(titular.cpf)) {
            cpfsRepeated.push(titular.cpf);
          } else {
            cpfsSet.add(titular.cpf);
          }
        }
        titular.dependentes.map((dependente) => {
          if (dependente.cpf) {
            if (cpfsSet.has(dependente.cpf)) {
              cpfsRepeated.push(dependente.cpf);
            } else {
              cpfsSet.add(dependente.cpf);
            }
          }
        });
      });
      return cpfsRepeated.join(",");
    },
    totalVidas: function () {
      return this.empresa.titulares.reduce(function (previous, current) {
        return previous + 1 + current.dependentes.length;
      }, 0);
    },
    planoAtual: function () {
      if (this.planos == null || this.planos.length === 0) {
        return null;
      }
      for (const index in this.planos) {
        if (
          this.totalVidas >= this.planos[index]["min_vidas"] &&
          this.totalVidas <= this.planos[index]["max_vidas"]
        ) {
          return this.planos[index];
        }
      }
      return null;
    },
    planoAtualNome: function () {
      const plano = this.planoAtual;
      return plano ? plano["produto_pme_nome"] : null;
    },
    totalValor: function () {
      const plano = this.planoAtual;
      if (
        this.empresa.pagamento === Pagamentos.BOLETO_ANUAL ||
        this.empresa.pagamento === Pagamentos.BOLETO_MENSAL
      ) {
        return plano ? `${plano["valor_boleto"] * this.totalVidas}` : null;
      }
      if (
        this.empresa.pagamento === Pagamentos.CARTAO_ANUAL ||
        this.empresa.pagamento === Pagamentos.CARTAO_MENSAL
      ) {
        return plano ? `${plano["valor"] * this.totalVidas}` : null;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async save() {
      this.$emit("save");
    },
    async continuar() {
      await this.save();
      this.$router.push({ name: "payment" });
    },
    async voltar() {
      await this.save();
      this.$router.push({ name: "company" });
    },
    addTitular() {
      this.empresa.titulares.push(new Titular());
    },
    addDependente(index) {
      this.empresa.titulares[index].dependentes.push(new Dependente());
      const position = this.empresa.titulares[index].dependentes.length - 1;
      const identifier = `modal-dependente-${index}-${position}`;
      setTimeout(() => {
        let modal = new Modal(document.getElementById(identifier));
        modal.show();
        setTimeout(() => {
          this.$refs[`observer-dependente-${index}`][position].validate();
        }, 1000);
      }, 300);
    },
    remDependente(index, indexDependente) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Ao confirmar, o dependente será removido",
          showCancelButton: true,
          confirmButtonColor: "#d9534f",
          confirmButtonText: "Remover",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.empresa.titulares[index].dependentes.splice(
              indexDependente,
              1
            );
          }
        });
    },
    remTitular(index) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Ao confirmar, o titular será removido",
          showCancelButton: true,
          confirmButtonColor: "#d9534f",
          confirmButtonText: "Remover",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.empresa.titulares.splice(index, 1);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
#main-container {
  margin-bottom: 10em;
}

/* Mobile */
@media only screen and (max-width: 999px) {
  #metodo-lado {
    display: none;
  }
}

/* Desktop */
@media only screen and (min-width: 1000px) {
  #metodo-baixo {
    display: none;
  }
}
</style>