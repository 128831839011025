var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('my-nav',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name !== 'begin' &&
      _vm.$route.name !== 'end' &&
      _vm.$route.name !== 'error' &&
      _vm.$route.name !== 'load'
    ),expression:"\n      $route.name !== 'begin' &&\n      $route.name !== 'end' &&\n      $route.name !== 'error' &&\n      $route.name !== 'load'\n    "}],attrs:{"debug":_vm.debug}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{attrs:{"empresa":_vm.empresa,"usuarioParceiro":_vm.usuarioParceiro,"planos":_vm.planos,"metodos":_vm.metodos,"parentescos":_vm.parentescos,"ufs":_vm.ufs,"debug":_vm.debug},on:{"save":_vm.save,"clear":_vm.clear,"setPlanos":_vm.setPlanos,"setUsuarioParceiro":_vm.setUsuarioParceiro}})],1),_c('my-footer',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name !== 'begin' &&
      _vm.$route.name !== 'end' &&
      _vm.$route.name !== 'error' &&
      _vm.$route.name !== 'load'
    ),expression:"\n      $route.name !== 'begin' &&\n      $route.name !== 'end' &&\n      $route.name !== 'error' &&\n      $route.name !== 'load'\n    "}],attrs:{"usuarioParceiro":_vm.usuarioParceiro}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }