import Dependente from './Dependente';
export default class Titular {
    constructor() {
        this.cpf = ''
        this.nome = ''
        this.celular = ''
        this.dataNascimento = ''
        this.nomeMae = ''
        this.email = ''

        this.dependentes = []
    }


    get cpfClean() {
        return this.cpf.replace(/[^\d]+/g, "")
    }

    get celularClean() {
        return this.celular.replace(/[^\d]+/g, "")
    }

    prepareToSendFinal() {
        return {
            "nome": this.nome.trim(),
            "cpf": this.cpfClean,
            "celular": this.celularClean,
            "email": this.email.trim(),
            "nome_mae": this.nomeMae.trim(),
            "data_nascimento":
                this.dataNascimento,
            "dependentes": this.dependentes.map((dependente) => {
                return dependente.prepareToSendFinal();
            })

        }
    }

    from(data) {
        this.cpf = data.cpf ?? ''
        this.nome = data.nome ?? ''
        this.celular = data.celular ?? ''
        this.dataNascimento = data.dataNascimento ?? ''
        this.nomeMae = data.nomeMae ?? ''
        this.email = data.email ?? ''

        this.dependentes = data.dependentes.map((dependente) => {
            const novoDependente = new Dependente();
            novoDependente.from(dependente)
            return novoDependente;
        })

    }
}