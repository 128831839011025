export default class Dependente {
    constructor() {
        this.cpf = ''
        this.nome = ''
        this.celular = ''
        this.dataNascimento = ''
        this.nomeMae = ''
        this.grauParentesco = ''
        this.email = ''
    }

    get cpfClean() {
        return this.cpf.replace(/[^\d]+/g, "")
    }

    get celularClean() {
        return this.celular.replace(/[^\d]+/g, "")
    }

    prepareToSendFinal() {
        return {
            "nome": this.nome.trim(),
            "cpf": this.cpfClean,
            "grau_parentesco": this.grauParentesco,
            "celular": this.celularClean,
            "email": this.email.trim(),
            "nome_mae": this.nomeMae.trim(),
            "data_nascimento":
                this.dataNascimento,

        }
    }

    from(data) {
        this.cpf = data.cpf ?? ''
        this.nome = data.nome ?? ''
        this.celular = data.celular ?? ''
        this.dataNascimento = data.dataNascimento ?? ''
        this.nomeMae = data.nomeMae ?? ''
        this.grauParentesco = data.grauParentesco ?? ''
        this.email = data.email ?? ''

    }
}