<template>
  <div class="container">
    <div class="row justify-content-center mt-4">
      <div class="col-12 col-lg-8">
        <div class="container" id="main-container">
          <ValidationObserver v-slot="{ invalid }" ref="form">
            <h4 class="mb-2 mt-4 color-custom-primary">
              <strong>Cadastro da Empresa</strong>
            </h4>
            <my-hr />
            <div id="empresa-area">
              <form @submit.prevent="" ref="empresaForm">
                <div class="form-group mb-3">
                  <label>CNPJ</label>
                  <input
                    class="form-control"
                    type="text"
                    v-mask="'##.###.###/####-##'"
                    v-model="empresa.cnpj"
                    readonly
                  />
                </div>
                <div class="row align-items-start">
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-text
                      label="E-mail"
                      rules="required|email|clienteEmailExists"
                      v-model="empresa.email"
                      name="email"
                      :onBlurEmmitName="save"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-phone
                      label="Celular"
                      rules="required|celular|clienteCelularExists"
                      v-model="empresa.celular"
                      name="celular"
                      :onBlurEmmitName="save"
                    />
                  </div>
                </div>
                <div class="row align-items-start">
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-date
                      label="Data de abertura"
                      rules="required|date"
                      v-model="empresa.dataAbertura"
                      name="data de abertura"
                      :onBlurEmmitName="save"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-phone
                      label="Telefone"
                      rules="celular|clienteCelularExists"
                      v-model="empresa.telefone"
                      name="telefone"
                      :onBlurEmmitName="save"
                    />
                  </div>
                </div>
                <my-input-text
                  label="Razão social"
                  rules="required|min:3|max:150|alpha_spaces"
                  v-model="empresa.razaoSocial"
                  name="razão social"
                  class="mb-3"
                  :onBlurEmmitName="save"
                />
                <my-input-text
                  label="Nome fantasia"
                  rules="required|min:3|max:150|alpha_spaces"
                  v-model="empresa.nomeFantasia"
                  name="nome fantasia"
                  class="mb-3"
                  :onBlurEmmitName="save"
                />
                <div class="row align-items-start">
                  <div class="col-8 mb-3">
                    <my-input-text
                      label="CEP"
                      rules="required|cep"
                      v-model="empresa.cep"
                      name="cep"
                      mask="#####-###"
                      placeholder="00000-000"
                      :onBlurEmmitName="save"
                    />
                  </div>
                  <div class="col-4 mb-3">
                    <my-input-select
                      label="Estado"
                      rules="required"
                      :onSaveEmmitName="save"
                      v-model="empresa.uf"
                      name="estado"
                      :options="ufs"
                    />
                  </div>
                </div>
                <div class="row align-items-start">
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-text
                      label="Bairro"
                      rules="required|min:3|max:150|alpha_spaces"
                      v-model="empresa.bairro"
                      name="bairro"
                      :onBlurEmmitName="save"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-text
                      label="Cidade"
                      rules="required|min:3|max:150|alpha_spaces"
                      v-model="empresa.cidade"
                      name="cidade"
                      :onBlurEmmitName="save"
                    />
                  </div>
                </div>
                <my-input-text
                  label="Logradouro"
                  rules="required|min:3|max:150"
                  v-model="empresa.endereco"
                  name="endereço"
                  class="mb-3"
                  :onBlurEmmitName="save"
                />
                <div class="row align-items-start">
                  <div class="col-4 mb-3">
                    <my-input-number
                      label="Número"
                      rules="required|numeric"
                      v-model="empresa.numero"
                      name="número"
                      :onBlurEmmitName="save"
                    />
                  </div>
                  <div class="col-8 mb-3">
                    <my-input-text
                      label="Complemento"
                      rules="max:100"
                      v-model="empresa.complemento"
                      name="complemento"
                      :onBlurEmmitName="save"
                    />
                  </div>
                </div>
              </form>
            </div>
            <h4 class="mb-2 mt-4 color-custom-primary">
              <strong>Cadastro de Dados do Responsável</strong>
            </h4>
            <my-hr />
            <div id="responsavel-area">
              <form @submit.prevent="">
                <my-input-text
                  label="Nome"
                  rules="required|min:3|max:255|alpha_spaces"
                  v-model="empresa.nomeResponsavel"
                  name="nome do responsável"
                  class="mb-3"
                  :onBlurEmmitName="save"
                />

                <div class="row align-items-start">
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-older-date
                      label="Data de nascimento"
                      rules="required|olderDate"
                      v-model="empresa.dataNascimentoResponsavel"
                      name="data de nascimento"
                      :onBlurEmmitName="save"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <my-input-text
                      label="CPF"
                      rules="required|cpf|clienteCPFExists"
                      v-model="empresa.cpfResponsavel"
                      name="cpf"
                      mask="###.###.###-##"
                      :onBlurEmmitName="save"
                    />
                  </div>
                </div>
                <my-input-text
                  label="Cargo"
                  rules="required|min:3|max:255|alpha_spaces"
                  v-model="empresa.cargo"
                  name="cargo"
                  class="mb-3"
                  :onBlurEmmitName="save"
                />
              </form>
            </div>
            <div class="d-grid">
              <my-button
                class="btn btn-block btn-success my-4 btn-lg"
                :disabled="invalid"
                :title="'Continuar'"
                @click.native="continuar"
              />
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Urls from "../models/URLS";
export default {
  props: ["debug", "empresa", "ufs"],
  data() {
    return {
      Urls,
    };
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    cepToSearch: {
      get: function () {
        return this.empresa.cep;
      },
    },
  },
  watch: {
    cepToSearch: function (oldValue, newValue) {
      if (newValue) {
        this.checkCEP();
      }
    },
  },
  methods: {
    async save() {
      this.$emit("save");
      this.$http({
        method: "post",
        url: Urls.LOJA_CLIENTE_PRE_CADASTRO,
        data: this.empresa.prepareToSendPre(),
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async continuar() {
      await this.save();
      this.$router.push({ name: "holder" });
    },
    checkCEP() {
      const cep = this.empresa.cepClean;
      if (cep == "" || cep.length != 8) return;
      this.$http({
        method: "get",
        url: `https://viacep.com.br/ws/${cep}/json/`,
      })
        .then((response) => {
          if (response.status == 200) {
            if (!response.data.erro) {
              this.empresa.bairro = response.data.bairro;
              this.empresa.cidade = response.data.localidade;
              this.empresa.endereco = response.data.logradouro;
              this.empresa.uf = response.data.uf;
            }
          }
        })
        .catch((response) => {
          if (this.debug) {
            console.log(response);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
#main-container {
  margin-bottom: 10em;
}
</style>