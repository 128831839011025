<template>
  <div class="container">
    <ValidationObserver v-slot="{ invalid }">
      <div class="row justify-content-center my-4">
        <div class="col-12 col-lg-8">
          <div class="container" id="main-container">
            <div
              v-if="
                empresa.pagamento === Pagamentos.CARTAO_ANUAL ||
                empresa.pagamento === Pagamentos.CARTAO_MENSAL
              "
              id="cartaoArea"
            >
              <h4 class="mb-2 mt-4 color-custom-primary">
                <strong>Pagamento com cartão</strong>
              </h4>
              <my-hr />
              <h4>Tela de cartão aqui</h4>
            </div>

            <h4 class="mb-2 mt-4 color-custom-primary">
              <strong>Proposta</strong>
            </h4>
            <my-hr />
            <div v-if="planos" id="final-area">
              <h5 class="mb-2 mt-4 color-custom-primary">
                <strong>Titulares</strong>
              </h5>
              <table class="table table-sm table-hover table-curved">
                <thead class="bg-custom-primary text-white">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    scope="col"
                    v-for="(titular, index) in empresa.titulares"
                    :key="index"
                  >
                    <td>{{ titular.nome }}</td>
                    <td>{{ titular.cpf }}</td>
                    <td>R$ {{ planoAtualValor }}</td>
                  </tr>
                </tbody>
              </table>
              <h5 class="mb-2 mt-4 color-custom-primary">
                <strong> Dependentes</strong>
              </h5>
              <table class="table table-sm table-hover table-curved">
                <thead class="bg-custom-primary text-white">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">CPF do Titular</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody
                  v-for="(titular, indexB) in empresa.titulares"
                  :key="indexB"
                >
                  <tr
                    v-for="(dependente, indexD) in titular.dependentes"
                    :key="indexD"
                    scope="col"
                  >
                    <td>{{ dependente.nome }}</td>
                    <td>{{ dependente.cpf }}</td>
                    <td>
                      {{ titular.cpf }}
                    </td>
                    <td>R$ {{ planoAtualValor }}</td>
                  </tr>
                </tbody>
              </table>
              <h5 class="mb-2 mt-4 color-custom-primary">
                <strong> Valores</strong>
              </h5>
              <table class="table table-sm table-hover table-curved">
                <thead class="bg-custom-primary text-white">
                  <tr>
                    <th scope="col">Vidas</th>
                    <th scope="col">Plano</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr scope="col">
                    <td>{{ totalVidas }}</td>
                    <td>
                      {{ planoAtualNome }}
                    </td>
                    <td>R$ {{ totalValor }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="alert alert-success text-center" role="alert">
                <h6>O método de pagamento escolhido foi:</h6>
                <h6>
                  <strong>{{ empresa.pagamento }}</strong>
                </h6>
                <h6 v-if="empresa.pagamento === Pagamentos.BOLETO_MENSAL">
                  Você receberá um E-mail mensalmente com o boleto.
                </h6>
                <h6 v-if="empresa.pagamento === Pagamentos.BOLETO_ANUAL">
                  Você receberá um E-mail anualmente com o boleto.
                </h6>
              </div>
              <my-input-due-date
                label="Data de vencimento/vigência"
                rules="required|dueDate"
                v-model="empresa.dataVencimento"
                name="data de vencimento"
                class="mb-3"
                :onBlurEmmitName="save"
              />
              <h4 class="mb-2 mt-4 color-custom-primary">
                <strong>Contrato</strong>
              </h4>
              <my-hr />
              <p>
                Para prosseguir, é necessário declarar que está ciente da
                contratação deste produto.
              </p>
              <p>
                Confira o contrato
                <a
                  :href="
                    Urls.STORAGE +
                    '/CONTRATO-ON-LINE-EMPRESARIAL-AESP-ODONTO-2019.pdf'
                  "
                  target="blank"
                  >aqui</a
                >
                .
              </p>

              <p>
                Os seus dados pessoais serão utilizados para processar a sua
                compra, apoiar a sua experiência em todo este site e para outros
                fins descritos na nossa
                <a :href="Urls.STORAGE + '/politica.pdf'" target="blank"
                  >Política de Privacidade</a
                >.
              </p>

              <div class="form-check mb-2">
                <ValidationProvider rules="is:aceito|required">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="termos"
                    true-value="aceito"
                    false-value="recusado"
                  />
                  <label class="form-check-label">
                    Li e aceito as condições dos
                    <a
                      :href="
                        Urls.STORAGE +
                        '/CONTRATO-ON-LINE-EMPRESARIAL-AESP-ODONTO-2019.pdf'
                      "
                      target="blank"
                      >Termos Legais</a
                    >,
                    <a
                      :href="
                        Urls.STORAGE +
                        '/CONTRATO-ON-LINE-EMPRESARIAL-AESP-ODONTO-2019.pdf'
                      "
                      target="blank"
                      >Contrato AESP ODONTO</a
                    >
                    ,
                    <a
                      :href="Urls.STORAGE + '/guia-leitura-contratual.pdf'"
                      target="blank"
                      >Guia de Leitura Contratual</a
                    >
                    e
                    <a
                      :href="Urls.STORAGE + '/manual-contratacao-plano.pdf'"
                      target="blank"
                      >Manual de Planos de Saúde</a
                    >
                    . Declaro ter ciência de todas
                    <a
                      :href="Urls.STORAGE + '/cobertura_e_plano.pdf'"
                      target="blank"
                      >Coberturas AESP ODONTO</a
                    >
                    e concordo com o
                    <a
                      :href="Urls.STORAGE + '/resumo_do_contrato.pdf'"
                      target="blank"
                      >Resumo do Contrato</a
                    >
                    .
                  </label>
                </ValidationProvider>
              </div>
              <div class="form-check">
                <ValidationProvider rules="is:aceito|required">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="promocoes"
                    true-value="aceito"
                    false-value="recusado"
                  />
                  <label class="form-check-label"
                    >Aceito receber informativos e promoções por e-mail e SMS e
                    demais meios de comunicação da AESP e de seus parceiros
                    comerciais.</label
                  >
                </ValidationProvider>
              </div>

              <div class="d-grid gap-2">
                <my-button
                  class="btn btn-block btn-success mt-4 btn-lg"
                  :disabled="invalid"
                  :title="'Continuar'"
                  @click.native="continuar"
                />
                <my-button
                  class="btn btn-success btn-lg"
                  title="Voltar"
                  @click.native="voltar"
                />
              </div>
            </div>
            <div v-else class="container text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Carregando...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import Urls from "../models/URLS";
import Pagamentos from "../models/PAGAMENTOS";
export default {
  props: ["planos", "empresa", "debug"],
  data() {
    return {
      promocoes: null,
      termos: null,
      Pagamentos,
      Urls,
    };
  },
  computed: {
    totalVidas: function () {
      return this.empresa.titulares.reduce(function (previous, current) {
        return previous + 1 + current.dependentes.length;
      }, 0);
    },
    planoAtual: function () {
      if (this.planos == null || this.planos.length === 0) {
        return null;
      }
      for (const index in this.planos) {
        if (
          this.totalVidas >= this.planos[index]["min_vidas"] &&
          this.totalVidas <= this.planos[index]["max_vidas"]
        ) {
          return this.planos[index];
        }
      }
      return null;
    },
    planoAtualNome: function () {
      const plano = this.planoAtual;
      return plano ? plano["produto_pme_nome"] : "Nenhum plano";
    },
    planoAtualValor: function () {
      const plano = this.planoAtual;
      return plano ? plano["valor_boleto"] : 0;
    },
    planoAtualId: function () {
      const plano = this.planoAtual;
      return plano ? plano["produto_pme_id"] : null;
    },
    totalValor: function () {
      const plano = this.planoAtual;
      if (
        this.empresa.pagamento === Pagamentos.BOLETO_ANUAL ||
        this.empresa.pagamento === Pagamentos.BOLETO_MENSAL
      ) {
        return plano ? `${plano["valor_boleto"] * this.totalVidas}` : "-";
      }
      return plano ? `${plano["valor"] * this.totalVidas}` : "-";
    },
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async save() {
      this.$emit("save");
    },
    async voltar() {
      await this.save();
      this.$router.push({ name: "holder" });
    },
    async jsonBetter(data) {
      const result = [];
      Object.entries(data).forEach(([key, value]) => {
        result.push(`<b>${key}</b>: ${value}`);
      });
      return result.join("<br/><br/>");
    },
    async continuar() {
      await this.save();
      this.$swal.showLoading();
      if (this.empresa.pagamento === Pagamentos.BOLETO_MENSAL) {
        this.$http({
          method: "post",
          url: Urls.LOJA_CLIENTE_BOLETO_MENSAL,
          data: this.empresa.prepareToSendFinal(this.planoAtualId),
        })
          .then((response) => {
            this.$swal.close();
            if (response.status == 204) {
              const pagamentoRealizado = this.empresa.pagamento;
              this.$emit("clear");
              setTimeout(() => {
                this.$router.push({
                  name: "end",
                  props: { pagamentoRealizado: pagamentoRealizado },
                });
              }, 100);
            } else {
              if (this.debug) {
                console.log(response);
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  response.data,
                  "errors_for_humans"
                )
              ) {
                this.jsonBetter(response.data.errors_for_humans).then(
                  (errorMessage) => {
                    this.$swal.fire({
                      title: "Erro",
                      html: errorMessage,
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                  }
                );
              } else if (
                Object.prototype.hasOwnProperty.call(response.data, "errors")
              ) {
                this.jsonBetter(response.data.errors).then((errorMessage) => {
                  this.$swal.fire({
                    title: "Erro",
                    html: errorMessage,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                });
              } else if (
                Object.prototype.hasOwnProperty.call(response.data, "error")
              ) {
                this.$swal.fire({
                  title: "Erro",
                  text: response.data.error,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              } else {
                this.$swal.fire({
                  title: "Erro",
                  text: "Ocorreu um erro desconhecido no envio",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            }
          })
          .catch((error) => {
            if (this.debug) {
              console.log(error);
            }
            this.$swal.fire({
              title: "Erro",
              text: "Ocorreu um erro na requisição",
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else {
        // adicionar novos métodos de pagamento acima, seguindo o padrão
        this.$swal.fire({
          title: "Erro",
          text: "Método de pagamento desconhecido ou não suportado no momento",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
#main-container {
  margin-bottom: 10em;
}
</style>