<template>
  <div class="form-group">
    <label class="mb-1">{{ label }}</label>
    <ValidationProvider :rules="rules" v-slot="{ errors, valid }">
      <input hidden type="text" :value="value" :name="name" />
      <select
        class="form-control"
        :value="value"
        :class="valid ? 'is-valid' : 'is-invalid'"
        v-on:input="updateValue($event.target.value)"
      >
        <option
          v-for="option in options"
          :key="option"
          :selected="option == value"
        >
          {{ option }}
        </option>
      </select>
      <div class="invalid-feedback">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
export default {
  props: ["label", "rules", "onSaveEmmitName", "value", "name", "options"],
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped>
</style>