import Urls from '../models/URLS'
import axios from 'axios';

export default async function (value) {
    try {
        const response = await axios({
            method: "post",
            url: Urls.TITULAR_CELULAR_EXISTS,
            data: { 'celular': value.replace(/[^\d]+/g, "") },
        });
        return response.status === 204
    } catch (e) {
        return false;
    }

}