<template>
  <nav
    class="navbar navbar-expand-lg navbar-light sticky-top bg-custom-primary justify-content-between row p-0 mx-0 shadow"
  >
    <div
      class="container col-lg-1 col-12 border-bottom bg-custom-primary justify-content-center navbar-logo-content"
    >
      <a class="navbar-brand">
        <img
          src="../../assets/img/min/logo.png"
          height="45px"
          class="d-inline-block"
          alt=""
        />
      </a>
    </div>
    <div class="container col-lg-11 col-12 justify-content-center">
      <div class="navbar-collapse text-center col row px-0" id="navbarNav">
        <ul id="progressbar" class="col mb-0 mt-1 px-0">
          <li class="active">
            <router-link
              :to="{ name: 'company' }"
              class="nav-link text-white"
              v-if="debug"
            >
              <font-awesome-icon icon="building" />
              <strong> Empresa</strong>
            </router-link>
            <div class="nav-link text-white" v-else>
              <font-awesome-icon icon="building" />
              <strong> Empresa</strong>
            </div>
          </li>
          <li :class="$route.name !== 'company' ? 'active' : ''">
            <router-link
              :to="{ name: 'holder' }"
              class="nav-link text-white"
              v-if="debug"
            >
              <font-awesome-icon icon="users" />
              <strong> Titulares</strong>
            </router-link>
            <div class="nav-link text-white" v-else>
              <font-awesome-icon icon="users" />
              <strong> Titulares</strong>
            </div>
          </li>
          <li :class="$route.name === 'payment' ? 'active' : ''">
            <router-link
              :to="{ name: 'payment' }"
              class="nav-link text-white"
              v-if="debug"
            >
              <font-awesome-icon icon="wallet" />
              <strong> Pagamento</strong>
            </router-link>
            <div class="nav-link text-white" v-else>
              <font-awesome-icon icon="wallet" />
              <strong> Pagamento</strong>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Urls from "../../models/URLS";
export default {
  props: ["debug"],
  data() {
    return {
      Urls,
    };
  },
};
</script>
<style scoped>
.navbar-logo-content {
  background-color: white;
  height: 75px;
}

/*progressbar*/
#progressbar {
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 11px;
  width: 33.33%;
  float: left;
  position: relative;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 11px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #5cb85c;
  color: white;
}
</style>