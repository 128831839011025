<template>
  <div class="form-group">
    <label class="mb-1">{{ label }}</label>
    <ValidationProvider :rules="rules" v-slot="{ errors, valid }">
      <input
        @blur="onBlurEmmitName"
        class="form-control"
        type="number"
        :value="value"
        :class="valid ? 'is-valid' : 'is-invalid'"
        :name="name"
        v-on:input="updateValue($event.target.value)"
        v-mask="mask"
        :placeholder="placeholder"
      />
      <div class="invalid-feedback">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
export default {
  props: [
    "label",
    "rules",
    "onBlurEmmitName",
    "value",
    "name",
    "mask",
    "placeholder",
  ],
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped>
</style>