<template>
  <div id="main-container" class="container-fluid">
    <div class="row align-top" id="logo-container"></div>
    <div class="row justify-content-start" id="message-row">
      <h1 class="col-10 col-sm-6 pl-5 color-custom-primary" id="mensagem">
        Cuidar do
        <span class="color-custom-weak" id="sorriso">sorriso</span> dos seus
        colaboradores é cuidar da imagem da sua empresa
      </h1>
    </div>
    <div
      class="row justify-content-center align-items-center centered mt-5"
      id="form-row"
    >
      <div
        class="col-10 col-md-6 rounded p-4 bg-custom-primary-transparent"
        id="form-container"
      >
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent>
            <my-input-text
              label="CNPJ da empresa"
              rules="required|cnpj"
              v-model="empresa.cnpj"
              name="cnpj"
              v-mask="'##.###.###/####-##'"
              placeholder="00.000.000/0000-00"
              class="text-white"
            />
            <div class="d-grid">
              <my-button
                class="btn-success mt-2"
                :disabled="invalid"
                :title="'Continuar'"
                @click.native="continuar"
              />
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import Urls from "../models/URLS";
export default {
  props: ["empresa", "debug"],
  data() {
    return {
      Urls,
    };
  },
  methods: {
    continuar(cnpj) {
      cnpj = this.empresa.cnpj.replace(/[^\d]+/g, "");
      this.$swal.showLoading();
      this.$http({
        method: "post",
        url: Urls.LOJA_CLIENTE_CHECK_CNPJ,
        data: {
          cnpj: cnpj,
        },
      })
        .then((response) => {
          if (response.status === 204) {
            this.$swal.close();
            this.$router.push({ name: "company" });
          } else if (response.status === 200) {
            if (Object.prototype.hasOwnProperty.call(response.data, "error")) {
              this.$swal.fire({
                title: "Atenção",
                text: response.data.error,
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (
              Object.prototype.hasOwnProperty.call(response.data, "errors")
            )
              this.$swal.fire({
                title: "Atenção",
                text: response.data.errors,
                icon: "warning",
                confirmButtonText: "OK",
              });
          }
        })
        .catch((error) => {
          if (this.debug) {
            console.log(error);
          }
          this.$swal.fire({
            title: "Atenção",
            text: "Ocorreu um erro na requisição",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
#message-row {
  padding-top: 2vh;
}

#mensagem {
  font-family: "Futura_Italic";
  line-height: 2rem;
}

#sorriso {
  font-family: "Michella";
}

/* Mobile */
@media only screen and (max-width: 999px) {
  #main-container {
    position: relative;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 60%,
        rgba(255, 255, 255, 1) 100%
      ),
      url(../assets/img/min/capa2.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    height: 100vh;
  }
  #logo-container {
    background-image: url(../assets/img/min/logo2.png);
    position: relative;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    height: 15vh;
  }

  #mensagem {
    font-size: 2rem;
  }

  #sorriso {
    font-size: 3.5rem;
  }
}

/* Desktop */
@media only screen and (min-width: 999px) and (max-width: 1599px) {
  #form-row {
    padding-top: 25vh;
  }
  #mensagem {
    line-height: 3rem;
  }
  #main-container {
    position: relative;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 90%,
        rgba(255, 255, 255, 1) 100%
      ),
      url(../assets/img/min/capa.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    height: 100vh;
  }

  #mensagem {
    font-size: 3rem;
    max-width: 40vw;
    margin-top: 3vh;
  }

  #sorriso {
    font-size: 4.5rem;
  }
}

/* Ultrawide */
@media only screen and (min-width: 1600px) {
  #form-row {
    padding-top: 25vh;
  }
  #mensagem {
    font-family: "Futura_Italic";
    line-height: 4rem;
  }
  #main-container {
    position: relative;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 90%,
        rgba(255, 255, 255, 1) 100%
      ),
      url(../assets/img/min/capa.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    height: 100vh;
  }

  #mensagem {
    font-size: 4rem;
    max-width: 40vw;
    margin-top: 10vh;
    margin-left: 15rem;
  }

  #sorriso {
    font-size: 5.5rem;
  }
}
</style>