<template>
  <div class="d-flex justify-content-center fullscreen">
    <div id="loading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import Urls from "../models/URLS";
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;
export default {
  props: ["empresa", "debug"],
  methods: {
    async getSaved() {
      // ou sessionStorage

      if (this.storageAvailable("localStorage")) {
        if (localStorage.getItem("store-user-data")) {
          try {
            this.empresa.fromJson(localStorage.getItem("store-user-data"));
            if (this.empresa.version !== process.env.VUE_APP_DATA_VERSION) {
              this.$emit("clear");
            }
          } catch (error) {
            this.pushToError(error.message);
          }
        }
      }
      return true;
    },
    storageAvailable(type) {
      let storage;
      try {
        storage = window[type];
        let x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return (
          e instanceof DOMException &&
          // everything except Firefox
          (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === "QuotaExceededError" ||
            // Firefox
            e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage &&
          storage.length !== 0
        );
      }
    },
    async pushToError(message) {
      this.$router
        .push({
          name: "error",
          params: {
            message,
          },
        })
        .catch((failure) => {
          if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
            if (this.debug) {
              console.info("Chamada duplicada em error");
            }
          } else {
            if (this.debug) {
              console.info("Ocorreu no push para 'error' do vue-router");
            }
          }
        });
    },
    async verifyErrorMessageAndPush(response, message) {
      if (response.status === 204) {
        return true;
      }
      if (response.status === 200) {
        if (Object.prototype.hasOwnProperty.call(response.data, "error")) {
          if (this.debug) {
            console.log(response.data.error);
          }
        } else if (
          Object.prototype.hasOwnProperty.call(response.data, "errors")
        ) {
          if (this.debug) {
            console.log(response.data.errors);
          }
        }
      }
      this.pushToError(message);
      return false;
    },
    async canSell(cv, op) {
      try {
        const response = await this.$http.post(Urls.USUARIO_PARCEIRO_CAN_SELL, {
          cv: cv,
          op: op,
        });

        return await this.verifyErrorMessageAndPush(
          response,
          "Erro na verificação do usuário parceiro"
        );
      } catch (error) {
        await this.pushToError("Ocorreu um erro na requisição");
        return false;
      }
    },
    async produtos(op) {
      try {
        const planos = await this.$http.get(
          `${Urls.PRODUTO_PME_COM_PRODUTO}/${op}`
        );
        if (planos.data.length === 0) {
          this.pushToError("Não existe produto para esta combinação");
          return false;
        } else {
          this.$emit("setPlanos", planos.data);
          return true;
        }
      } catch (error) {
        await this.pushToError("Ocorreu um erro na requisição");
        return false;
      }
    },
    async usuarioParceiro(cv) {
      try {
        const usuarioParceiro = await this.$http.get(
          `${Urls.USUARIO_PARCEIRO}/${cv}`
        );
        this.$emit("setUsuarioParceiro", usuarioParceiro.data);
        return true;
      } catch (error) {
        if (error.response.status === 400) {
          await this.pushToError(
            "Ocorreu um erro no carregamento do usuário parceiro"
          );
        } else {
          await this.pushToError("Ocorreu um erro na requisição");
        }
        return false;
      }
    },
    async load(cv, op) {
      const call1 = await this.canSell(cv, op);
      let call2 = false;
      if (call1) {
        call2 = await this.produtos(op);
      }
      let call3 = false;
      if (call2) {
        call3 = await this.usuarioParceiro(cv);
      }
      let call4 = false;
      if (call3) {
        call4 = await this.getSaved();
      }
      if (call1 && call2 && call3 && call4) {
        try {
          this.empresa.cv = cv;
          this.empresa.op = op;
          await this.$router.push({
            name: "begin",
          });
        } catch (error) {
          if (this.debug) {
            console.log(error);
          }
          this.pushToError(
            "Ocorreu um erro no redirecionamento para a página inicial"
          );
        }
      } else {
        this.pushToError("Erro no carregamento dos dados do servidor");
      }
    },
  },
  mounted() {
    if (this.debug) {
      console.log(`no load cv é ${this.empresa.cv} e op é ${this.empresa.op}`);
    }

    if (
      isNaN(this.empresa.cv) ||
      isNaN(this.empresa.op) ||
      !Number.isInteger(parseInt(this.empresa.cv)) ||
      !Number.isInteger(parseInt(this.empresa.op))
    ) {
      this.pushToError(
        "Usuário parceiro e corretor precisam ser números inteiros"
      );
    } else {
      this.load(this.empresa.cv, this.empresa.op);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
#loading {
  width: 10rem;
  height: 10rem;
  color: $aespcolor;
  border-width: 1rem;
}
</style>