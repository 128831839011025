<template>
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-lg-8">
        <div class="container" v-if="usuarioParceiro" id="infoArea">
          <div class="row justify-content-center">
            <div class="col-12">
              <h4 class="mb-2 mt-4 font-weight-bold color-custom-primary">
                Finalizado
              </h4>
              <my-hr />
            </div>
            <div
              class="row justify-content-center"
              style="background-color: white"
            >
              <div class="m-4 text-center">
                <font-awesome-icon
                  icon="check-circle"
                  class="color-green"
                  size="10x"
                />
              </div>
              <div class="text-center my-4 col-10 color-custom-primary">
                <h4>
                  <p v-if="pagamentoRealizado === Pagamentos.BOLETO_MENSAL">
                    Você receberá um e-mail com o boleto para pagamento da 1ª
                    mensalidade do seu plano escolhido.
                  </p>
                  <p v-if="pagamentoRealizado === Pagamentos.BOLETO_ANUAL">
                    Você receberá um e-mail com o boleto anual do seu plano
                    escolhido.
                  </p>
                  <p v-if="pagamentoRealizado === Pagamentos.CARTAO_ANUAL">
                    Você receberá a cobraça anual no seu cartão de crédito do
                    seu plano escolhido.
                  </p>
                  <p v-if="pagamentoRealizado === Pagamentos.CARTAO_MENSAL">
                    Você receberá a cobrança no seu cartão de crédito para
                    pagamento da 1ª mensalidade do seu plano escolhido.
                  </p>
                  <br />
                  <p>
                    Seu plano estará ativo em até 24 horas após a confirmação do
                    pagamento.
                  </p>
                  <br />
                  <p>
                    Para mais informações, entre em contato com
                    {{ usuarioParceiro.nome }}.
                    <br />
                    <a
                      class="font-weight-bold"
                      v-if="usuarioParceiro.celular"
                      :href="`tel:+55${usuarioParceiro.celular}`"
                      >{{ usuarioParceiro.celular }}</a
                    ><a
                      class="font-weight-bold"
                      v-else-if="usuarioParceiro.telefone"
                      :href="`tel:+55${usuarioParceiro.telefone}`"
                      >{{ usuarioParceiro.telefone }}</a
                    >
                    <br />
                    <a
                      class="font-weight-bold"
                      v-if="usuarioParceiro.email"
                      :href="`mailto:${usuarioParceiro.email}`"
                      >{{ usuarioParceiro.email }}</a
                    >

                    .
                  </p>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagamentos from "../models/PAGAMENTOS";
export default {
  props: ["usuarioParceiro", "pagamentoRealizado"],
  data() {
    return {
      Pagamentos,
    };
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/scss/style.scss";
#infoArea {
  margin-bottom: 5rem;
}
</style>