<template>
  <footer class="footer fixed-bottom mx-auto bg-custom-primary pt-1 mx-0">
    <div class="container-fluid">
      <div class="justify-content-center">
        <div class="text-center text-white">
          <div
            v-if="usuarioParceiro"
            class="row justify-content-lg-around justify-content-center align-items-center"
          >
            <div class="col-lg-3 col-12 justify-content-center">
              <h6>
                <font-awesome-icon icon="user-tie" class="mr-1" /> Corretor:
                {{ usuarioParceiro.nome }}
              </h6>
            </div>
            <div class="col-lg-3 col-12 justify-content-center">
              <h6 v-if="usuarioParceiro.celular">
                <font-awesome-icon icon="phone" class="mr-1" />
                Fale comigo:
                <a :href="`tel:+55${usuarioParceiro.celular}`">{{
                  usuarioParceiro.celular
                }}</a>
              </h6>
              <h6 v-else-if="usuarioParceiro.telefone">
                <font-awesome-icon icon="phone" class="mr-1" />
                Fale comigo:
                <a :href="`tel:+55${usuarioParceiro.telefone}`">{{
                  usuarioParceiro.telefone
                }}</a>
              </h6>
            </div>
            <div
              v-if="usuarioParceiro.email"
              class="col-lg-3 col-12 justify-content-center"
            >
              <h6>
                <font-awesome-icon icon="envelope" class="" />
                E-mail:
                <a :href="`mailto:${usuarioParceiro.email}`">{{
                  usuarioParceiro.email
                }}</a>
              </h6>
            </div>
            <div class="col-lg-3 col-12 justify-content-center">
              <h6>
                AESP Odonto © {{ new Date().getFullYear() }} - Todos os direitos
                reservados
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: { usuarioParceiro: Object },
};
</script>