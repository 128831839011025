const API_PREFIX = process.env.VUE_APP_API_PREFIX;
const API_STORAGE = process.env.VUE_APP_API_STORAGE;

export default {
    PRODUTO_PME_COM_PRODUTO: `${API_PREFIX}/produto-pme/com-produto`,
    
    CORRETOR: `${API_PREFIX}/corretor`,

    LOJA_CLIENTE_CHECK_CNPJ: `${API_PREFIX}/loja-cliente/check-cnpj`,

    LOJA_CLIENTE_BOLETO_MENSAL: `${API_PREFIX}/compra/boleto-mensal`,
    LOJA_CLIENTE_PRE_CADASTRO: `${API_PREFIX}/compra/pre-cadastro`,

    TITULAR_CPF_EXISTS: `${API_PREFIX}/titular/cpf-exists`,
    TITULAR_CELULAR_EXISTS: `${API_PREFIX}/titular/celular-exists`,
    TITULAR_EMAIL_EXISTS: `${API_PREFIX}/titular/email-exists`,

    DEPENDENTE_CPF_EXISTS: `${API_PREFIX}/dependente/cpf-exists`,
    DEPENDENTE_CELULAR_EXISTS: `${API_PREFIX}/dependente/celular-exists`,
    DEPENDENTE_EMAIL_EXISTS: `${API_PREFIX}/dependente/email-exists`,

    CLIENTE_CPF_EXISTS: `${API_PREFIX}/cliente/cpf-exists`,
    CLIENTE_CELULAR_EXISTS: `${API_PREFIX}/cliente/celular-exists`,
    CLIENTE_EMAIL_EXISTS: `${API_PREFIX}/cliente/email-exists`,

    USUARIO_PARCEIRO: `${API_PREFIX}/usuario-parceiro`,
    USUARIO_PARCEIRO_CAN_SELL: `${API_PREFIX}/usuario-parceiro/can-sell`,

    STORAGE: `${API_STORAGE}`,

    IMG: `${API_STORAGE}/img`
};
