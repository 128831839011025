<template>
  <div id="app">
    <my-nav
      :debug="debug"
      v-show="
        $route.name !== 'begin' &&
        $route.name !== 'end' &&
        $route.name !== 'error' &&
        $route.name !== 'load'
      "
    />
    <transition name="fade" mode="out-in">
      <router-view
        :empresa="empresa"
        :usuarioParceiro="usuarioParceiro"
        :planos="planos"
        :metodos="metodos"
        :parentescos="parentescos"
        :ufs="ufs"
        :debug="debug"
        @save="save"
        @clear="clear"
        @setPlanos="setPlanos"
        @setUsuarioParceiro="setUsuarioParceiro"
      />
    </transition>

    <my-footer
      :usuarioParceiro="usuarioParceiro"
      v-show="
        $route.name !== 'begin' &&
        $route.name !== 'end' &&
        $route.name !== 'error' &&
        $route.name !== 'load'
      "
    />
  </div>
</template>
<script>
import Empresa from "./models/Empresa";
import Pagamentos from "./models/PAGAMENTOS";
import Parentescos from "./models/PARENTESCOS";
import Ufs from "./models/UFS";
export default {
  mounted() {
    const queryValues = this.getQueryString();
    this.empresa.cv = queryValues.cv;
    this.empresa.op = queryValues.op;

    if (this.debug) {
      console.log(`cv é ${this.empresa.cv} e op é ${this.empresa.op}`);
    }

    if (this.empresa.cv && this.empresa.op) {
      this.$router.push({ name: "load" });
    } else {
      this.$router.push({
        name: "error",
        params: { message: "Faltam parâmetros na URL" },
      });
    }
  },
  computed: {
    debug() {
      if (process.env.VUE_APP_APP_DEBUG) {
        if (typeof process.env.VUE_APP_APP_DEBUG === "string")
          if (process.env.VUE_APP_APP_DEBUG.toUpperCase() === "TRUE") {
            return true;
          }
      }
      return false;
    },
  },
  methods: {
    save() {
      // ou sessionStorage
      if (this.storageAvailable("localStorage")) {
        try {
          localStorage.setItem("store-user-data", this.empresa.toJson());
        } catch (error) {
          if (this.debug) {
            console.log(error);
          }
        }
      }
    },
    clear() {
      if (this.storageAvailable("localStorage")) {
        try {
          localStorage.removeItem("store-user-data");
          this.empresa = new Empresa();
        } catch (error) {
          if (this.debug) {
            console.log(error);
          }
        }
      }
    },
    storageAvailable(type) {
      let storage;
      try {
        storage = window[type];
        let x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return (
          e instanceof DOMException &&
          // everything except Firefox
          (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === "QuotaExceededError" ||
            // Firefox
            e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage &&
          storage.length !== 0
        );
      }
    },
    getQueryString() {
      let a = window.location.search
        .substr(1)
        .split("&")
        .concat(window.location.hash.substr(1).split("&"));

      if (typeof a === "string") a = a.split("#").join("&").split("&");

      // se não há valores, retorna um objeto vazio
      if (!a) return {};

      let b = {};
      for (let i = 0; i < a.length; ++i) {
        // obtem array com chave/valor
        let p = a[i].split("=");

        // se não houver valor, ignora o parametro
        if (p.length != 2) continue;

        // adiciona a propriedade chave ao objeto de retorno
        // com o valor decodificado, substituindo `+` por ` `
        // para aceitar URLs codificadas com `+` ao invés de `%20`
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
      }
      // retorna o objeto criado
      return b;
    },
    setPlanos(planos) {
      this.planos = planos;
    },
    setUsuarioParceiro(usuarioParceiro) {
      this.usuarioParceiro = usuarioParceiro;
    },
  },
  data() {
    return {
      planos: null,
      usuarioParceiro: null,
      empresa: new Empresa(),
      metodos: [Pagamentos.BOLETO_MENSAL],
      parentescos: [Parentescos.CONJUGE, Parentescos.FILHO, Parentescos.OUTROS],
      ufs: [
        Ufs.AC,
        Ufs.AL,
        Ufs.AP,
        Ufs.AM,
        Ufs.BA,
        Ufs.CE,
        Ufs.DF,
        Ufs.ES,
        Ufs.GO,
        Ufs.MA,
        Ufs.MT,
        Ufs.MS,
        Ufs.MG,
        Ufs.PA,
        Ufs.PB,
        Ufs.PR,
        Ufs.PE,
        Ufs.PI,
        Ufs.RJ,
        Ufs.RN,
        Ufs.RS,
        Ufs.RO,
        Ufs.RR,
        Ufs.SC,
        Ufs.SP,
        Ufs.SE,
        Ufs.TO,
      ],
    };
  },
};
</script>

<style lang="scss">
@import "./assets/style/scss/style.scss";
@font-face {
  font-family: "Futura_Italic";
  src: url(./assets/fonts/FUTURA_BK_BT_BOOK_ITALIC.TTF);
}

@font-face {
  font-family: "Futura";
  src: url(./assets/fonts/FUTURA_BK_BT_BOOK.TTF);
}

@font-face {
  font-family: "Michella";
  src: url(./assets/fonts/MICHELLA_GARDEN.OTF);
}

* {
  font-family: "Futura";
}
</style>
