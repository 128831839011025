<template>
  <div class="form-group">
    <label class="mb-1">{{ label }}</label>
    <ValidationProvider :rules="rules" v-slot="{ errors, valid }" :name="name">
      <input
        @blur="onBlurEmmitName"
        class="form-control"
        type="date"
        :value="value"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        :class="valid ? 'is-valid' : 'is-invalid'"
        :name="name"
        v-on:input="updateValue($event.target.value)"
        :max="max"
        :min="min"
      />
      <div class="invalid-feedback">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
export default {
  props: ["label", "rules", "onBlurEmmitName", "value", "name"],
  data() {
    const today = new Date();
    let max = new Date();
    let min = new Date();
    max.setDate(today.getDate() + 30);
    max = max.toISOString().slice(0, 10);
    min.setDate(today.getDate() + 1);
    min = min.toISOString().slice(0, 10);
    return {
      min: min,
      max: max,
    };
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped>
</style>